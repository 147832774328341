.about {
  color: #000;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  user-select: none;
  font-size: 11px;
}
.about img {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0;
  will-change: opacity;
}
.about span {
  will-change: transform;
  margin-left: 14px;
  font-family: NanumGothic;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.02em;
  text-align: left;
  display: inline-block;
}
/*# sourceMappingURL=src/components/AboutButton.css.map */