
.homeButton
	padding 20px
	background: linear-gradient(180deg, rgba(0, 151, 254, 0.5) 0%, #0097FE 100%);
	box-shadow: 0px 18px 63px rgba(249, 27, 91, 0.09), 0px 4.02054px 14.0719px rgba(249, 27, 91, 0.0536497), 0px 1.19702px 4.18956px rgba(249, 27, 91, 0.0363503);	border-radius: 15px;
	user-select none
	margin-bottom 10px
	color #fff
	will-change transform, opacity
	.illustration
		margin-top 20px
		width 120px
		height auto
	.title
		font-family: 'NanumGothic';
		font-size: 15px;
		font-style: normal;
		font-weight: 800;
		line-height: 18px;
		letter-spacing: -0.02em;
		text-align: left;
	.desc
		margin-top 20px
		font-family: 'HK Grotesk Pro';
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 13px;
		letter-spacing: -0.03em;
		text-align: left;
	h4
		margin-top 10px
		color #333
		font-size 12px
		padding 0
		margin-bottom 0
	&.darkMode
		color #000
		.desc
			color #828282

