.invite {
  color: #fff;
  cursor: pointer;
  user-select: none;
  width: 100%;
  text-align: center;
  margin-top: 85px;
  position: relative;
}
.invite img {
  opacity: 0;
  will-change: transform;
  position: absolute;
  top: -50px;
  left: 0;
  width: 101px;
}
.invite img.right {
  width: 75px;
  top: -50px;
  left: auto;
  right: 0;
}
.invite .credits {
  margin-top: 20px;
  font-family: HK Grotesk Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: #828282;
}
.invite:hover {
  color: #fafafa;
}
.invite span {
  color: #2e2e2e;
  font-family: 'Neue Montreal';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  font-size: 11px;
  background: #fff;
  background-clip: padding-box; /* !importanté */
  border: solid 2px transparent; /* !importanté */
  border-radius: 40px;
  padding: 10px 20px;
  position: relative;
}
.invite span:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0ff;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background: linear-gradient(100.57deg, rgba(255,83,121,0.5), #ff5379);
}
/*# sourceMappingURL=src/components/InviteFriendButton.css.map */