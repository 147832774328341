.LearnButton {
  padding: 20px;
  background: linear-gradient(180deg, rgba(0,151,254,0.5) 0%, #0097fe 100%);
  box-shadow: 0px 18px 63px rgba(249,27,91,0.09), 0px 4.02054px 14.0719px rgba(249,27,91,0.054), 0px 1.19702px 4.18956px rgba(249,27,91,0.036);
  border-radius: 15px;
  user-select: none;
  margin-bottom: 10px;
  color: #fff;
  will-change: transform, opacity;
}
.LearnButton .illustration {
  width: 120px;
  height: auto;
  position: absolute;
  top: 20px;
  left: 2px;
  z-index: 10;
}
.LearnButton .illustrationContainer {
  position: relative;
  min-height: 130px;
}
.LearnButton .title {
  font-family: 'NanumGothic';
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
}
.LearnButton .desc {
  margin-top: 20px;
  font-family: 'HK Grotesk Pro';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -0.03em;
  text-align: left;
}
.LearnButton h4 {
  margin-top: 10px;
  color: #333;
  font-size: 12px;
  padding: 0;
  margin-bottom: 0;
}
.LearnButton.darkMode {
  color: #000;
}
.LearnButton.darkMode .desc {
  color: #828282;
}
.LearnButton .baguette {
  width: 22px;
  height: 257px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.LearnButton .baguette1 {
  z-index: 15;
}
/*# sourceMappingURL=src/components/home/LearnButton.css.map */