
.cloud
	will-change transform, opacity
	background-image: url('/img/views/home/cloud.png')
	background-size: contain;
	background-repeat no-repeat
	position: absolute;
	height 80%
	width 100%
	overflow hidden
	z-index -1
	top: 0px
	left: 0px
	&.reversed
		background-image: url('/img/views/booking/cloud.png')
