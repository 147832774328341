
	.features
		margin-left 20px
		margin-top 40px
		text-align left
		.desc
			font-family: 'HK Grotesk Pro';
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 14px;
			letter-spacing: -0.03em;
			color #828282
		.cta
			span
				margin-left 7px
				margin-right 12px
		img
			display inline-block
			vertical-align middle
