#home {
  padding-top: 92px;
}
#home .title {
  text-align: left;
  margin-left: 20px;
}
#home .row {
  margin-top: 45px;
  display: flex;
  padding: 0 11px;
  column-gap: 11px;
}
#home .column {
  flex: 50%;
}
#home .col2 {
  margin-top: 60px;
}
/*# sourceMappingURL=src/views/Home.css.map */