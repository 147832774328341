
#home
	padding-top 92px
	.title
		text-align left
		margin-left 20px
	.row {
		margin-top 45px
		display: flex;
		padding 0 11px
		column-gap: 11px;
	}
	.column {
		flex: 50%;
	}
	.col2{
		margin-top:60px
	}
